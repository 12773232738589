.home__container {
  row-gap: 7rem;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}
.home__social-icon {
  font-size: 2.8 rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__title {
  font-size: 2.5rem;
  margin-bottom: var(--mb-0-25);
  fill: none;
  display: inline-block;
}

.letter__bounce-name {
  display: inline-block;
}

.letter__bounce-name:hover {
  color: var(--title-color-dark);
  animation: animate--bounce-name 0.6s ease-in-out infinite;
  fill: black;
}

.home__hand {
  width: 38px;
  height: 38px;
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__subtitle::before {
  content: "";
  position: absolute;
  width: 55px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 1rem;
}

.home__img {
  background: url(../../assets/profile_bg.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgba(15 15 15/10%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

@keyframes animate--bounce-name {
  25% {
    transform: scale(0.8, 1.3);
  }
  50% {
    transform: scale(1.1, 0.8);
  }
  75% {
    transform: scale(0.7, 1.2);
  }
}

.home__scroll {
  margin-left: 9.2rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}
