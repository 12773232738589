.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.nav {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color-medium);
  font-weight: var(--font-medium);
}

/* .nav__logo_hover,
.nav__logo:hover {
  color: var(--title-color-dark);
} */

.nav__list {
  display: flex;
  column-gap: 2rem;
}

.nav__sm {
  display: flex;
  column-gap: 1rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.active__link,
.nav__link:hover {
  color: var(--title-color-dark);
}
.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

/* BREAKPOINTS */
