.contact__list {
  display: flex;
  column-gap: 2rem;
  justify-content: center;
}

.contact_menu {
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.contact__social-icon {
  font-size: 2.8 rem;
  color: var(--title-color);
}

.contact-icon {
  font-size: 2rem;
}
