.work__container {
  max-width: 768px;
}

.work__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.work__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin: 0 var(--mb-1);
  cursor: pointer;
}

.work__button:hover {
  color: var(--title-color-dark);
}

.work__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.work__sections {
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
}

.work__content {
  display: block;
}

.work__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.work__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.work_calender {
  font-size: var(--small-font-size);
}

.work__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--text-color);
  border-radius: 50%;
}

.work__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--text-color);
  transform: translate(6px, -7px);
}
